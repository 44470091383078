
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRoute, useRouter } from "vue-router";
import { moveDom } from "@/directives/moveDom";
import Layout from "@/pages/wechatNirvana/components/layout.vue";
import CompareList from "@/pages/wechatNirvana/components/compareList.vue"

export default defineComponent({
  components: {
    Layout, CompareList
  },
  directives: {
    move: moveDom,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;
    const { nirvana_type, shr_number, group_key, stage } = route.query


    const getNirvanaDisparity = () => {
      store.dispatch("actGetNirvanaDisparity", {
        userid, username, dept_id,
        nirvana_type: route.query.nirvana_type as string,
        shr_number: route.query.shr_number as string,
        group_key: route.query.group_key as string,
        stage: route.query.stage as string,
      }).then(res => {
        if (res.status == 200) {
          dataMap.dataList = res.data_list
          dataMap.isLoading = false;
        }
      }).catch(err => {
        console.log("err")
      })
    }


    const dataMap = reactive({
      dataList: {} as GetNirvanaDisparityReq,
      msg: "数据加载中...",
      isLoading: true,
    });


    getNirvanaDisparity()

    const back = () => {
      router.push("wechatNirvana")
    }
    return {
      ...toRefs(dataMap), back
    };
  },
});
